export let users = [
    {
        "id": 1,
        "title": "Rajiv Gandhi",
        "location": "Dewas",
        "service-title": "Service-title",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 2,
        "title": "Amay Shivore",
        "location": "indore",
        "service-title": "Service-title",
        "post-category": "Post-category",
        "category": "Painter",
        "status": "Active"
    },
    {
        "id": 3,
        "title": "Rahul Vyas",
        "location": "Bhopal",
        "service-title": "Service-title",
        "post-category": "Post-category",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 4,
        "title": "Aditya roy",
        "location": "Bhopal",
        "category": "Painter",
        "service-title": "Service-title",
        "post-category": "Post-category",
        "status": "Active"
    },
    {
        "id": 5,
        "title": "Numersh Rai",
        "location": "indore",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 6,
        "title": "Nimrit Mehta",
        "location": "Dewas",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 7,
        "title": "Naman Dutt",
        "location": "Dewas",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 8,
        "title": "carpenter 1",
        "location": "indore",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 9,
        "title": "carpenter 1",
        "location": "Khandwa",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 10,
        "title": "carpenter 1",
        "location": "Khandwa",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 11,
        "title": "carpenter 1",
        "location": "indore",
        "category": "Carpenter",
        "status": "Active"
    },
    {
        "id": 12,
        "title": "carpenter 1",
        "location": "indore",
        "category": "Carpenter",
        "status": "Active"
    }
];
