import React from "react";
import postimage from './../../assests/images/post1.jpg';
const post = ()=>{
    return(
        <>
        <div className="post-wrp">
            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="post-image">
                                                            <img src={postimage}/>
                                                        </div>
                                                        <div className="post-discription">
                                                            <div className="post-top">
                                                            <h5>Post heading</h5>
                                                            <h6>23/12/2022</h6>
                                                            </div>
                                                            <div className="post-category">
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            </div>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="post-image">
                                                            <img src={postimage}/>
                                                        </div>
                                                        <div className="post-discription">
                                                            <div className="post-top">
                                                            <h5>Post heading</h5>
                                                            <h6>23/12/2022</h6>
                                                            </div>
                                                            <div className="post-category">
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            </div>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="post-image">
                                                            <img src={postimage}/>
                                                        </div>
                                                        <div className="post-discription">
                                                            <div className="post-top">
                                                            <h5>Post heading</h5>
                                                            <h6>23/12/2022</h6>
                                                            </div>
                                                            <div className="post-category">
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            </div>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="post-image">
                                                            <img src={postimage}/>
                                                        </div>
                                                        <div className="post-discription">
                                                            <div className="post-top">
                                                            <h5>Post heading</h5>
                                                            <h6>23/12/2022</h6>
                                                            </div>
                                                            <div className="post-category">
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            <h6>Post category</h6>
                                                            </div>
                                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                               </div>
        </>
    )
}
export default post;